import { getRandomColor, calculatePercentage } from "@/tools/functions";
import { Util } from '@colven/common-domain-lib/lib';

export default {
    name: 'InventoryCardComponent',
    components: {},
    props: {
        thingId: {
            type: String,
            required: true
        },
        thingName: {
            type: String,
            required: true
        },
        fuelLevel1: {
            type: Number,
            required: false,
            default: null,
        },
        fuelLevel2: {
            type: Number,
            required: false,
            default: null,
        },
        fuelLevel3: {
            type: Number,
            required: false,
            default: null,
        },
        fuelLevel4: {
            type: Number,
            required: false,
            default: null,
        },
        fuelLevelTotal: {
            type: Number,
            required: true
        },
        tanksCapacity: {
            type: Object,
            required: true
        },
        totalFuelColor: {
            type: String,
            required: false,
            default: () => {
                return getRandomColor();
            }
        },
        tanksFuelBackgroundColor: {
            type: String,
            required: false,
            default: () => {
                return getRandomColor();
            }
        },
        unit:{
            type: String,
            required: false,
            default: 'L'
        }
    },
    data: () => ({
        //capacidad máxima entre todos los tanques del equipo
        totalFuelCapacity: null,
        //porcentaje del nivel actual de los tanques para las progress bars
        fuelLevel1Percentage: null,
        fuelLevel2Percentage: null,
        fuelLevel3Percentage: null,
        fuelLevel4Percentage: null,
        totalFuelPercentage: null,
        //capacidades de cada tanque
        t1cap: 0,
        t2cap: 0,
        t3cap: 0,
        t4cap: 0
    }),
    async mounted() {
        this.initializeFuels();
    },
    created() {},
    beforeDestroy() {},
    computed: {},
    methods: {
        initializeFuels() {
            
            this.t1cap = this.tanksCapacity?.tank1 ? this.tanksCapacity.tank1.capacity : 0;
            this.t2cap = this.tanksCapacity?.tank2 ? this.tanksCapacity.tank2.capacity : 0;
            this.t3cap = this.tanksCapacity?.tank3 ? this.tanksCapacity.tank3.capacity : 0;
            this.t4cap = this.tanksCapacity?.tank4 ? this.tanksCapacity.tank4.capacity : 0;
            
            //cálculo de porcentajes para las progress bars de la tarjeta
            this.fuelLevel1Percentage = calculatePercentage(this.fuelLevel1, this.t1cap);
            this.fuelLevel2Percentage = calculatePercentage(this.fuelLevel2, this.t2cap);
            this.fuelLevel3Percentage = calculatePercentage(this.fuelLevel3, this.t3cap);
            this.fuelLevel4Percentage = calculatePercentage(this.fuelLevel4, this.t4cap);

            //calculo de capacidad máxima (suma de las capacidades de los tanques)
            this.totalFuelCapacity = Util.roundDecimals(this.t1cap + this.t2cap + this.t3cap + this.t4cap);

            //cálculo de porcentaje para la progress bar de total de combustible de la tarjeta
            this.totalFuelPercentage = calculatePercentage(this.fuelLevelTotal, this.totalFuelCapacity);
        }
    },
    watch: {}
}