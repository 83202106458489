import { mapActions } from "vuex";
import NoDataAvailableComponent from "@/components/commons/no-data-available/NoDataAvailableComponent.vue";
import InventoryCardComponent from "@/components/inventory/inventory-card/InventoryCardComponent.vue";
import FilterComponent from "@/components/filter/FilterComponent.vue";
import { inventoryService } from "@/business/inventory.service";
import { SnackbarStyle, REPORT_TYPES } from "@/constants/constants";
// El logo de Gestya
import base64 from "../../assets/base64";
// Para exportar a formato pdf
import jsPDF from "jspdf";
// Para exportar los canvas a diferentes formatos
import { saveAs } from "file-saver";
// La función para activar la pantalla completa
import { calculatePercentage, getRandomColor, toggleFullscreen, lightenDarkenColor } from "@/tools/functions";
// Para exportar elementos del DOM a imágenes
import domtoimage from "dom-to-image";
import i18n from "@/i18n";
import { configurationService } from "@/business/configurationService";
import { filterService } from "@/business/filterService";
// Para clonar objetos
import { clone } from 'ramda';

export default {
  components: {
    NoDataAvailableComponent,
    InventoryCardComponent,
    FilterComponent,
  },
  props: {
    width: {
      required: false,
      default: () => {
        return "100%";
      },
    },
    height: {
      required: false,
      default: () => {
        return "100%";
      },
    },
  },
  data: () => ({
    unit: 'L',
    // el logo de gestya
    gestyaLogoBase64: null,
    // el logo de la empresa
    enterpriseLogoBase64: null,
    // ícono del botón para ir/salir de pantalla completa
    fullscreenIcon: "fullscreen",
    // loading de exportación
    exportLoading: false,
    // search
    searchValue: null,
    // mostrar-ocultar los filtros
    showFilters: false,
    // modelo del filtro
    filterModel: filterService.getFilterModel(),
    dataForRangeFilter: {
      showRangeData: true,
      minLimit: 0,
      maxLimit: 5000,
      propertyName: i18n.t("inventory.filter.rangePropName"),
    },
    maxQuantityLimit: 100,
    orderFilterWorstBestPropertyName: i18n.t('inventory.filter.orderPropName'),
    // datos
    inventoryData: [],
    // loading datos
    loading: false,
    // para no mostrar el mensaje "no hay datos disponibles" al inicio
    init: true,
    //titulo y color de la cantidad total de combustible de todos los equipos juntos
    totalFuelTitle: i18n.t("inventory.totalFuelTitle"),
    totalFuelColor: getRandomColor(),
    //cantidad total de todo el combustible disponible sumado
    totalFuelPercentage: 0,
    totalFuel: 0,
    totalCapacity: 0,
    //colores para los niveles de combustible de los tanques de cada thing
    colors: null,
    lightenPrimary: null,
    thingTotalFuelColor: getRandomColor(),
    thingTanksFuelColor: getRandomColor(),
    //Timer del autorefresh
    timer: ""
  }),
  async mounted() {
    // Generar Base64 del logo de Gestya
    this.gestyaLogoBase64 = base64.gestya;
    // Generar Base64 del logo de la empresa
    if (localStorage.getItem("logo")) {
      this.enterpriseLogoBase64 = localStorage.getItem("logo");
    }
    // recuperar configuración de filtros y obtener datos
    this.getFilterConfigurationForReport();
    //obtener colores de usuario
    this.colors = await inventoryService.getUserColors();
    this.lightenPrimary = lightenDarkenColor(this.colors.primary, 80);
    //Autorefresh cada 5 minutos
    this.timer = setInterval(() => {this.getReport(this.filterModel)}, 300000);
  },
  beforeDestroy() {
    this.closeReportDefer();
    this.cancelAutoRefresh();
  },
  computed: {
    /**
     * Filtra las tarjetas de acuerdo al valor del campo de búsqueda
     * @returns
     */
    filteredInventory() {
      if (this.searchValue) {
        const regex = new RegExp(this.searchValue, "i");
        const filterFunction = (element) => {
          return regex.test(element.thingName);
        };
        const filteredData = this.inventoryData.filter((element) => filterFunction(element));
        return filteredData;
      } else {
        return this.inventoryData;
      }
    },
    /**
     * Para ocultar el scrollbar en la exportación
     */
    exportCards() {
      if (this.exportLoading) {
        return "hide-scrollbar";
      }
    },
    exportCardsRow() {
      if (this.exportLoading) {
        return "pr-3";
      }
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar",
      closeSnackbar: "snackbar/closeSnackbar",
      showReportDefer: "reportDefer/showReportDefer",
      closeReportDefer: "reportDefer/closeReportDefer",
    }),
    /**
     * Para mostrar el panel de filtros
     */
    showFilter() {
      this.showFilters = !this.showFilters
    },
    /**
     * Método que se ejecuta cuando recibe el evento "saveChanges"
     * TODO: validar si se deben guardar los filtros en la configuración del usuario
     */
    saveFilterChanges() {
      this.showFilters = false;
      this.saveFilterConfigurationForReport({ filters: this.filterModel });
      this.getReport(this.filterModel);
    },
    /**
     * Método para guardar la configuración según el usuario de los filtros aplicados para el reporte
     * @param {*} configuration
     */
    saveFilterConfigurationForReport(configuration) {
      configurationService
        .save(REPORT_TYPES.INVENTORY, configuration)
        .then(() => {
          this.showSnackbar({
            visible: true,
            text: this.$t("user.configuration.saveSuccess"),
            timeout: 10000,
            style: SnackbarStyle.SUCCESS
          })
        })
        .catch(() => {
          this.showSnackbar({
            visible: true,
            text: this.$t("user.configuration.saveError"),
            timeout: 10000,
            style: SnackbarStyle.ERROR
          })
        })
    },
    /**
     * Método para obtener la configuración de los filtros para el reporte según el usuario actual, si la hubiera
     */
    async getFilterConfigurationForReport() {
      const configuration = await configurationService.get(REPORT_TYPES.INVENTORY);
      const data = configuration ?  configuration.data : null;
      if (data != null && data.filters != null) {
        this.filterModel = clone(data.filters);
        this.getReport(this.filterModel);
      } else {
        this.showFilters = true;
      }
    },
    /**
     * Obtener datos para el reporte
     * @param {*} filters
     */
    async getReport(filters) {
      // TODO: reporte diferido por notificaciones
      this.loading = true;
      let data = await inventoryService.getInventoryReport(filters);
      if (data != null) {
        this.inventoryData.cleanAndUpdate(data.thingsData);
        this.totalFuel = data.totalFuel;
        this.totalCapacity = data.totalCapacity;
        this.totalFuelPercentage = calculatePercentage(data.totalFuel, data.totalCapacity);
        this.unit = data.unit?? 'L';
        this.init = false;
      } else {
        this.showFilters = true;
      }
      this.loading = false;
    },
    /**
     * Exportar a una imagen en formato PNG
     */
    async exportToImage(png = true) {
      this.exportLoading = true;
      const date = new Date();
      const title = `${this.$t(
        "exportFilename.inventory"
      )}_${date.getDate()}-${date.getMonth() +
        1}-${date.getFullYear()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      const node = document.getElementById("cardsForExport");
      const blob = await domtoimage.toBlob(node, {
        width: node.scrollWidth,
        height: node.scrollHeight,
      });
      if (png) {
        saveAs(blob, `${title}.png`);
      } else {
        saveAs(blob, `${title}.jpg`);
      }
      this.exportLoading = false;
    },
    /**
     * Método para exportar a PDF
     */
    async exportToPDF(print = false) {
      this.exportLoading = true;
      const date = new Date();
      const title = `${this.$t(
        "exportFilename.inventory"
      )}_${date.getDate()}-${date.getMonth() +
        1}-${date.getFullYear()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

      // tarjetas
      const node = document.getElementById("cardsForExport");
      const dataUrl = await domtoimage.toPng(node, {
        height: node.scrollHeight,
      });

      // GENERO EL PDF Y LO GUARDO
      const doc = jsPDF("l", "mm", "a4");
      doc.page = 1;
      // Ancho y altura de la imagen en el pdf
      const pdfWidth = doc.internal.pageSize.getWidth();
      // const pdfHeight = ratio * pdfWidth
      doc.addImage(dataUrl, "PNG", 0, 20);
      // Header
      if (this.enterpriseLogoBase64) {
        doc.addImage(this.enterpriseLogoBase64, "PNG", 5, 2, 50, 10);
      }
      doc.line(5, 15, pdfWidth - 5, 15);
      // Footer
      doc.addImage(this.gestyaLogoBase64, "PNG", 5, doc.internal.pageSize.height - 12, 50, 10);
      doc.line(5, doc.internal.pageSize.height - 15, pdfWidth - 5, doc.internal.pageSize.height - 15);

      if (print) {
        // Imprimir el pdf
        doc.autoPrint();
        window.open(doc.output("bloburl"), "_blank");
      } else {
        // Descargar el pdf
        doc.save(title);
      }

      this.exportLoading = false;
    },
    /**
     * Para ir a pantalla completa
     */
    goToFullscreen() {
      let tabsContainer = document.getElementById("InventoryComponent");
      this.fullscreenIcon = toggleFullscreen(tabsContainer);
    },
    /**
     * Ícono del botón de pantalla completa
     */
    switchFullscreenIcon() {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        this.fullscreenIcon = "fullscreen";
      } else {
        this.fullscreenIcon = "fullscreen_exit";
      }
    },
    cancelAutoRefresh() {  
      clearInterval(this.timer);  
    }
  },
};
