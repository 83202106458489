import http from "@/middleware";

export const inventoryApi = {
    /**
     * Obtener los datos para el reporte de inventario
     * @returns
     */
    async getInventoryReport(filters) {
        return await http.post('/api/inventory/get-report', filters);
    }
}

export default inventoryApi;