import inventoryApi from "@/api/inventory.api";
import { Util } from '@colven/common-domain-lib/lib';
import { processFilters } from "@/tools/functions";
import store from "@/store/store";
import { unitParser } from '@colven/common-domain-lib/lib';
import { TANK_PREFIX } from "../constants/constants";
export const inventoryService = (function () {

    /**
     * Obtener los datos para el reporte de inventario
     * @returns 
     */
    async function getInventoryReport(filters) {
        const filterData = processFilters(filters);
        let response = await inventoryApi.getInventoryReport(filterData);
        return processInventoryData(response.data);
    }

    function parseVolumen(liters) {
        return Util.roundDecimals(Number(unitParser.parseVolumen(Number(liters), store.getters['user/getInternationalization'].unit, false)))
    }

    /**
     * Procesa los datos para el reporte de inventario
     * @param {*} data 
     */
    function processInventoryData(data) {
        let internationalizationStore = store.getters['user/getInternationalization'];
        console.log(internationalizationStore);
        let unit = unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.VOLUME);

        const result = {
            thingsData: [],
            totalFuel: 0,
            totalCapacity: 0,
            unit: unit
        }

        data.forEach(t => {
            const thing = {};
            thing.idThing = t.idThing;

            //nombre
            thing.thingName = t.thingName;

            // nivel actual de cada tanque
            let tank1 = 0, tank2 = 0, tank3 = 0, tank4 = 0;

            //tanque 1
            thing.fuelLevel1 = parseVolumen(t.varilla.fuelLevel1);
            tank1 = thing.fuelLevel1;

            //tanque 2
            thing.fuelLevel2 = parseVolumen(t.varilla.fuelLevel2);
            tank2 = thing.fuelLevel2;

            //tanque 3
            thing.fuelLevel3 = parseVolumen(t.varilla.fuelLevel3);
            tank3 = thing.fuelLevel3;

            //tanque 4
            thing.fuelLevel4 = parseVolumen(t.varilla.fuelLevel4);
            tank4 = thing.fuelLevel4;

            //capacidades de los tanques 
            thing.capacities = t.capacities;
            for (let tank in thing.capacities) {
                if (tank.toLowerCase().includes(TANK_PREFIX.toLowerCase())) thing.capacities[tank].capacity = parseVolumen(thing.capacities[tank].capacity)
            }
            const t1cap = t.capacities.tank1 ? t.capacities.tank1.capacity : 0;
            const t2cap = t.capacities.tank2 ? t.capacities.tank2.capacity : 0;
            const t3cap = t.capacities.tank3 ? t.capacities.tank3.capacity : 0;
            const t4cap = t.capacities.tank4 ? t.capacities.tank4.capacity : 0;

            let totalThingCapacity = Util.roundDecimals(t1cap + t2cap + t3cap + t4cap);

            //total de combustible
            thing.totalFuel = Util.roundDecimals(tank1 + tank2 + tank3 + tank4);

            result.thingsData.push(thing);
            result.totalFuel += thing.totalFuel;
            result.totalCapacity += totalThingCapacity;
        });

        result.totalFuel = Util.roundDecimals(result.totalFuel);
        result.totalCapacity = Util.roundDecimals(result.totalCapacity);
        return result;
    }

    function getUserColors() {
        const themes = JSON.parse(localStorage.getItem('themes'));
        const darkLocalStorage = localStorage.getItem("dark") === "true";
        if (darkLocalStorage) {
            return { primary: themes.dark.primary, secondary: "white" }
        }
        return { primary: themes.light.primary, secondary: "white" }
    }

    return {
        getInventoryReport,
        getUserColors
    };
})();